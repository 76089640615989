import setGlobalProperty from '@/helpers/setGlobalProperty';
import googleOneTap from '@/socialite/googleOneTap';
import store from '@/store';

const googleAuth = (function ga() {
  let currentReject;
  let currentResolve;
  let google;
  let tokenClient;

  const hasLoadedGoogleOauth2 = () => !!(google?.accounts?.oauth2);

  const installClient = () => new Promise((resolve) => {
    const el = document.createElement('script');
    el.src = 'https://accounts.google.com/gsi/client';
    el.async = true;
    el.defer = true;

    // eslint-disable-next-line no-multi-assign
    el.onreadystatechange = el.onload = function onLoad() {
      if (!el.readyState || /loaded|complete/.test(el.readyState)) {
        setTimeout(() => {
          google = window.google;
          resolve();
        }, 500);
      }
    };

    document.head.appendChild(el);
  });

  const initializeClient = (config) => {
    tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: config.clientId,
      scope: config.scope,
      callback: (response) => currentResolve(response),
      error_callback: (error) => currentReject(error),
    });
  };

  const initializeOneTap = (config) => {
    google.accounts.id.initialize({
      client_id: config.clientId,
      use_fedcm_for_prompt: true,
      cancel_on_tap_outside: false,
      callback: googleOneTap,
    });
    google.accounts.id.prompt();
  };

  function Auth() {
    this.closeOneTap = () => google?.accounts?.id?.cancel();

    this.load = (config) => {
      this.loading = new Promise((resolve, reject) => {
        installClient()
          .then(async () => {
            if (config.isOneTapEnabled && await store.state.$auth.authenticate() === false) {
              initializeOneTap(config);
            }
            resolve();
          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            reject(error);
          });
      });

      return this.loading;
    };

    this.initializeButton = (config) => {
      this.loading = new Promise((resolve, reject) => {
        if (hasLoadedGoogleOauth2()) {
          initializeClient(config);
          resolve();

          return;
        }

        installClient()
          .then(() => {
            initializeClient(config);
            resolve();
          }).catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            reject(error);
          });
      });
    };

    this.signIn = () => this.loading.then(() => new Promise((resolve, reject) => {
      if (hasLoadedGoogleOauth2()) {
        currentResolve = resolve;
        currentReject = reject;
        tokenClient.requestAccessToken();
      } else {
        reject();
      }
    }));

    this.signOut = (token) => {
      if (hasLoadedGoogleOauth2()) {
        google.accounts.oauth2.revoke(token);
      }
    };
  }

  return new Auth();
}());

export default function googleAuthPackage(Vue, options) {
  if (Vue.prototype.$gAuth) {
    return;
  }

  setGlobalProperty(Vue, '$gAuth', googleAuth);

  googleAuth.load(options);
}
